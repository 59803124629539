import React, { ChangeEvent, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  FormControl,
  TextareaAutosize,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  MenuItem,
  InputAdornment,
  Chip,
  Autocomplete,
  FormHelperText,
  IconButton,
  Tooltip,
  Radio,
  Checkbox,
} from '@mui/material';
import '../../styles/style.css';
import { LeadUrl } from '../../services/ApiUrls';
import { fetchData } from '../../components/FetchData';
import { CustomAppBar } from '../../components/CustomAppBar';
import {
  FaArrowDown,
  FaFileUpload,
  FaPalette,
  FaPercent,
  FaPlus,
  FaTimes,
  FaUpload,
} from 'react-icons/fa';
import { useForm } from '../../components/UseForm';
import {
  CustomSelectField,
  RequiredTextField,
  StyledSelect,
} from '../../styles/CssStyled';

// const useStyles = makeStyles({
//   btnIcon: {
//     height: '14px',
//     color: '#5B5C63'
//   },
//   breadcrumbs: {
//     color: 'white'
//   },
//   fields: {
//     height: '5px'
//   },
//   chipStyle: {
//     backgroundColor: 'red'
//   },
//   icon: {
//     '&.MuiChip-deleteIcon': {
//       color: 'darkgray'
//     }
//   }
// })

// const textFieldStyled = makeStyles(() => ({
//   root: {
//     borderLeft: '2px solid red',
//     height: '35px'
//   },
//   fieldHeight: {
//     height: '35px'
//   }
// }))

// function getStyles (name, personName, theme) {
//   return {
//     fontWeight:
//       theme.typography.fontWeightRegular
//   }
// }

type FormErrors = {
  title?: string[];
  first_name?: string[];
  last_name?: string[];
  account_name?: string[];
  phone?: string[];
  telephone?: string[];
  email?: string[];
  lead_attachment?: string[];
  opportunity_amount?: string[];
  website?: string[];
  description?: string[];
  teams?: string[];
  assigned_to?: string[];
  contacts?: string[];
  status?: string[];
  source?: string[];
  address_line?: string[];
  street?: string[];
  city?: string[];
  state?: string[];
  postcode?: string[];
  country?: string[];
  tags?: string[];
  company?: string[];
  probability?: number[];
  industry?: string[];
  skype_ID?: string[];
  file?: string[];
  insurance?: string[];
  insurance_name?: string[];
  medicare?: string[];
  // specific_complaint?: string[];
  // chromic_condition_management: string[];
  // wellness_management: string[];
  prescriptions?: string[];
  controlled_prescriptions?: string[];
  specialist?: string[];
  specialist_name?: string[]
};
interface FormData {
  title: string;
  first_name: string;
  last_name: string;
  account_name: string;
  phone: string;
  telephone: string;
  email: string;
  lead_attachment: string | null;
  opportunity_amount: string;
  website: string;
  description: string;
  teams: string;
  assigned_to: string[];
  contacts: string[];
  status: string;
  source: string;
  address_line: string;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  tags: string[];
  company: string;
  probability: number;
  industry: string;
  skype_ID: string;
  file: string | null;
  insurance?: string;
  insurance_name?: string;
  medicare?: string;
  specific_complaint: boolean,
  chromic_condition_management: boolean,
  wellness_management: boolean,
  prescriptions?: string;
  controlled_prescriptions?: string;
  specialist?: string;
  specialist_name?: string
}

export function AddLeads() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const autocompleteRef = useRef<any>(null);
  const [error, setError] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [selectedAssignTo, setSelectedAssignTo] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any[]>([]);
  const [errors, setErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<FormData>({
    title: '',
    first_name: '',
    last_name: '',
    account_name: '',
    phone: '',
    telephone: '',
    email: '',
    lead_attachment: null,
    opportunity_amount: '',
    website: '',
    description: '',
    teams: '',
    assigned_to: [],
    contacts: [],
    status: 'new',
    source: 'call',
    address_line: '',
    street: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    tags: [],
    company: '',
    probability: 1,
    industry: 'ADVERTISING',
    skype_ID: '',
    file: null,
    insurance: "",
    insurance_name: "",
    medicare: "",
    specific_complaint: false,
    chromic_condition_management: false,
    wellness_management: false,
    prescriptions: "",
    controlled_prescriptions: "",
    specialist: "",
    specialist_name: ""
  });

  // const changeHandler = (event: any) => {
  //   if (event.target.files[0]) {
  //     // setLogo(event.target.files[0])
  //     const reader = new FileReader()
  //     reader.addEventListener('load', () => {
  //       // setImgData(reader.result)
  //       // setLogot(true)
  //     })
  //     val.lead_attachment = event.target.files[0]
  //   }
  // }

  // Function to validate US phone number
  const validateUSPhoneNumber = (phoneNumber: string) => {
    const usPhoneRegex = /^(?:\+1|1)?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    return usPhoneRegex.test(phoneNumber);
  };
  
  // Function to validate telephone number
  const validateTelephoneNumber = (telephoneNumber: string) => {
    // Define your regex for telephone number validation
    // Modify the regex pattern to match your desired format
    const telephoneRegex = /^(?:\+1|1)?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    return telephoneRegex.test(telephoneNumber);
  };

  const handleChange2 = (title: any, val: any) => {
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // console.log('nd', val)
    if (title === 'contacts') {
      setFormData({
        ...formData,
        contacts: val.length > 0 ? val.map((item: any) => item.id) : [],
      });
      setSelectedContacts(val);
    } else if (title === 'assigned_to') {
      setFormData({
        ...formData,
        assigned_to: val.length > 0 ? val.map((item: any) => item.id) : [],
      });
      setSelectedAssignTo(val);
    } else if (title === 'tags') {
      setFormData({
        ...formData,
        assigned_to: val.length > 0 ? val.map((item: any) => item.id) : [],
      });
      setSelectedTags(val);
    }
    // else if (title === 'country') {
    //   setFormData({ ...formData, country: val || [] })
    //   setSelectedCountry(val);
    // }
    else {
      setFormData({ ...formData, [title]: val });
    }
  };
  const handleChange = (e: any) => {
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // console.log('e.target',e)
    const { name, value, files, type, checked, id } = e.target;

    // console.log('auto', val)
    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files?.[0] || null });
    } else if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'radio') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    // console.log("data", formData.medicare);
  };


  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
  
    setFormData({
      ...formData,
        [name]: checked,
    });

    // console.log(name, checked);
  };
  

  const backbtnHandle = () => {
    navigate('/app/leads');
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };
  const submitForm = () => {
    // console.log('Form data:', formData.lead_attachment,'sfs', formData.file);
    const data = {
      title: formData.title,
      first_name: formData.first_name,
      last_name: formData.last_name,
      account_name: formData.account_name,
      phone: formData.phone,
      telephone: formData.telephone,
      email: formData.email,
      // lead_attachment: formData.lead_attachment,
      lead_attachment: formData.file,
      opportunity_amount: formData.opportunity_amount,
      website: formData.website,
      description: formData.description,
      teams: formData.teams,
      assigned_to: formData.assigned_to,
      contacts: formData.contacts,
      status: formData.status,
      source: formData.source,
      address_line: formData.address_line,
      street: formData.street,
      city: formData.city,
      state: formData.state,
      postcode: formData.postcode,
      country: formData.country,
      tags: formData.tags,
      company: formData.company,
      probability: formData.probability,
      industry: formData.industry,
      skype_ID: formData.skype_ID,
      insurance: formData.insurance,
      insurance_name: formData.insurance_name,
      medicare: formData.medicare,
      specific_complaint: formData.specific_complaint,
      chromic_condition_management: formData.chromic_condition_management,
      wellness_management: formData.wellness_management,
      prescriptions: formData.prescriptions,
      controlled_prescriptions: formData.controlled_prescriptions,
      specialist: formData.specialist,
      specialist_name: formData.specialist_name
    };
    
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('Token'),
      org: localStorage.getItem('org'),
    };
    fetchData(`${LeadUrl}/`, 'POST', JSON.stringify(data), headers)
      .then((res: any) => {
        // console.log('Form data:', res);
        if (!res.error) {
          resetForm();
          navigate('/app/leads');
        }
        if (res.error) {
          setError(true);
          setErrors(res?.errors);
        }
      })
      .catch(() => {});
  };
  const resetForm = () => {
    setFormData({
      title: '',
      first_name: '',
      last_name: '',
      account_name: '',
      phone: '',
      telephone: '',
      email: '',
      lead_attachment: null,
      opportunity_amount: '',
      website: '',
      description: '',
      teams: '',
      assigned_to: [],
      contacts: [],
      status: 'new',
      source: 'call',
      address_line: '',
      street: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      tags: [],
      company: '',
      probability: 1,
      industry: 'ADVERTISING',
      skype_ID: '',
      file: null,
      insurance: "",
      insurance_name: "",
      medicare: "",
      specific_complaint: false,
      chromic_condition_management: false,
      wellness_management: false,
      prescriptions: "",
      controlled_prescriptions: "",
      specialist: "",
      specialist_name: ""
    });
    setErrors({});
    setSelectedContacts([]);
    setSelectedAssignTo([]);
    setSelectedTags([]);
    // setSelectedCountry([])
    // if (autocompleteRef.current) {
    //   console.log(autocompleteRef.current,'ccc')
    //   autocompleteRef.current.defaultValue([]);
    // }
  };
  const onCancel = () => {
    resetForm();
    navigate('/app/leads');
  };

  const module = 'Leads';
  const crntPage = 'Add Lead';
  const backBtn = 'Back To Leads';

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // setFormData({ ...formData, lead_attachment: reader.result as string });
        setFormData({ ...formData, file: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  // console.log(state, 'leadsform')
  return (
    <Box sx={{ mt: '60px' }}>
      <CustomAppBar
        backbtnHandle={backbtnHandle}
        module={module}
        backBtn={backBtn}
        crntPage={crntPage}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
      <Box sx={{ mt: '100px' }}>
        <form onSubmit={handleSubmit}>
          <div style={{ padding: '10px' }}>
            {/* contact details */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div
                    style={{
                      borderBottom: '1px solid lightgray',
                      width: '100%',
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: '15px',
                        fontWeight: 'bold',
                        color: '#1A3353',
                      }}
                    >
                      Contact Details
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="fieldContainer">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">First Name</div>
                        <RequiredTextField
                          name="first_name"
                          required
                          value={formData.first_name}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.first_name?.[0] ? errors?.first_name[0] : ''
                          }
                          error={!!errors?.first_name?.[0]}
                        />
                      </div>
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Last Name</div>
                        <RequiredTextField
                          name="last_name"
                          required
                          value={formData.last_name}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.last_name?.[0] ? errors?.last_name[0] : ''
                          }
                          error={!!errors?.last_name?.[0]}
                        />
                      </div>
                    </div>
                    <div className="fieldContainer2">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Mobile Number</div>
                          <TextField
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            style={{ width: '70%' }}
                            size="small"
                            helperText={
                              formData.phone.length > 0 && !validateUSPhoneNumber(formData.phone) ? 'Invalid mobile number' : ''
                            }
                            error={ formData.phone.length > 0 && !validateUSPhoneNumber(formData.phone)}
                            />
                      </div>

                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Email Address</div>
                        {/* <div style={{ width: '40%', display: 'flex', flexDirection: 'row', marginTop: '19px', marginLeft: '6.6%' }}>
                        <div style={{ marginRight: '10px', fontSize: '13px', width: '22%', textAlign: 'right', fontWeight: 'bold' }}>Email Address</div> */}
                        <TextField
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={errors?.email?.[0] ? errors?.email[0] : ''}
                          error={!!errors?.email?.[0]}
                        />

                      </div>
                    </div>
                    <div className="fieldContainer2">
                      <div className="fieldSubContainer">
                      <div className="fieldTitle">Home Number</div>
                        <TextField
                          name="telephone"
                          value={formData.telephone}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            formData.telephone.length > 0 && !validateTelephoneNumber(formData.telephone) ? 'Invalid home number' : ''
                          }
                          error={ formData.telephone.length > 0 && !validateTelephoneNumber(formData.telephone)}
                          />
                      </div>
                      <div className="fieldSubContainer">
                        {/* <div className="fieldTitle">Job Title</div>
                        <RequiredTextField
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.title?.[0] ? errors?.title[0] : ''
                          }
                          error={!!errors?.title?.[0]}
                        /> */}
                      </div>
                    </div>

                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* address details */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div
                    style={{
                      borderBottom: '1px solid lightgray',
                      width: '100%',
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: '15px',
                        fontWeight: 'bold',
                        color: '#1A3353',
                      }}
                    >
                      Address
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="fieldContainer">
                      <div className="fieldSubContainer">
                        <div
                          className="fieldTitle"
                          // style={{ marginRight: '10px', fontSize: '13px', width: '22%', textAlign: 'right', fontWeight: 'bold' }}
                        >
                          Street Address
                        </div>
                        <TextField
                          name="address_line"
                          value={formData.address_line}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.address_line?.[0]
                              ? errors?.address_line[0]
                              : ''
                          }
                          error={!!errors?.address_line?.[0]}
                        />
                      </div>
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Address 2</div>
                        <TextField
                          name="street"
                          value={formData.street}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.street?.[0] ? errors?.street[0] : ''
                          }
                          error={!!errors?.street?.[0]}
                        />
                      </div>
                    </div>
                    <div className="fieldContainer2">
                    <div className="fieldSubContainer">
                        <div className="fieldTitle">City</div>
                        <TextField
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={errors?.city?.[0] ? errors?.city[0] : ''}
                          error={!!errors?.city?.[0]}
                        />
                      </div>
                     
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">State</div>
                        <TextField
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.state?.[0] ? errors?.state[0] : ''
                          }
                          error={!!errors?.state?.[0]}
                        />
                      </div>
                    </div>
                    <div className="fieldContainer2">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Zipcode</div>
                        <TextField
                          name="postcode"
                          value={formData.postcode}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.postcode?.[0] ? errors?.postcode[0] : ''
                          }
                          error={!!errors?.postcode?.[0]}
                        />
                      </div>
                      <div className="fieldSubContainer">
                        {/* <div className="fieldTitle">Zipcode</div>
                        <TextField
                          name="postcode"
                          value={formData.postcode}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          helperText={
                            errors?.postcode?.[0] ? errors?.postcode[0] : ''
                          }
                          error={!!errors?.postcode?.[0]}
                        /> */}
                      </div>
                      {/* <div className="fieldSubContainer">
                        <div className="fieldTitle">Country</div>
                        <CustomSelectField
                          name="country"
                          select
                          value={formData.country}
                          InputProps={{
                            style: {
                              height: '40px',
                              maxHeight: '40px',
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              // getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  maxHeight: '200px',
                                },
                              },
                            },
                          }}
                          onChange={handleChange}
                          sx={{ width: '70%' }}
                          helperText={
                            errors?.country?.[0] ? errors?.country[0] : ''
                          }
                          error={!!errors?.country?.[0]}
                        >
                          {state?.countries?.length &&
                            state?.countries.map((option: any) => (
                              <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                              </MenuItem>
                            ))}
                        </CustomSelectField> */}
                        {/* <FormControl error={!!errors?.country?.[0]} sx={{ width: '70%' }}>
                          <Autocomplete
                            // ref={autocompleteRef}
                            // freeSolo
                            value={selectedCountry}
                            options={state.countries || []}
                            getOptionLabel={(option: any) => option[1]}
                            onChange={(e: any, value: any) => handleChange2('country', value)}
                            size='small'
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  deleteIcon={<FaTimes style={{ width: '9px' }} />}
                                  sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    height: '18px'

                                  }}
                                  variant='outlined'
                                  label={option[1]}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            popupIcon={<IconButton
                              disableFocusRipple
                              disableRipple
                              sx={{
                                width: '45px', height: '40px',
                                borderRadius: '0px',
                                backgroundColor: '#d3d3d34a'
                              }}><FaArrowDown style={{ width: '15px' }} /></IconButton>}
                            renderInput={(params) => (
                              <TextField {...params}
                                // placeholder='Add co'
                                InputProps={{
                                  ...params.InputProps,
                                  sx: {
                                    '& .MuiAutocomplete-endAdornment': {
                                      mt: '-9px',
                                      mr: '-8px'
                                    }
                                  }
                                }}
                              />
                            )}
                          />
                          <FormHelperText>{errors?.country?.[0] || ''}</FormHelperText>
                        </FormControl> */}
                      {/* </div> */}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* Lead details  */}
            <div className="leadContainer">
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div className="typography">
                    <Typography
                      style={{
                        marginBottom: '15px',
                        fontWeight: 'bold',
                        color: '#1A3353',
                      }}
                    >
                      Lead Info
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="fieldContainer2">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Assign To</div>
                        <FormControl
                          error={!!errors?.assigned_to?.[0]}
                          sx={{ width: '70%' }}
                        >
                          <Autocomplete
                            // ref={autocompleteRef}
                            multiple
                            value={selectedAssignTo}
                            // name='contacts'
                            limitTags={2}
                            options={state.users}
                            // options={state.contacts ? state.contacts.map((option: any) => option) : ['']}
                            getOptionLabel={(option: any) =>
                              option?.user__email
                            }
                            onChange={(e: any, value: any) =>
                              handleChange2('assigned_to', value)
                            }
                            size="small"
                            filterSelectedOptions
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  deleteIcon={
                                    <FaTimes style={{ width: '9px' }} />
                                  }
                                  sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    height: '18px',
                                  }}
                                  variant="outlined"
                                  label={option?.user__email}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            popupIcon={ 
                            <IconButton
                              sx={{
                                width: '45px',
                                height: '40px',
                                borderRadius: '0px',
                                backgroundColor: '#d3d3d34a',
                              }}
                            >
                              <FaPlus style={{ width: '15px' }} />
                            </IconButton>
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Add Users"
                                InputProps={{
                                  ...params.InputProps,
                                  sx: {
                                    '& .MuiAutocomplete-endAdornment': {
                                      mt: '-9px',
                                      mr: '-8px',
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                          {state?.assigned_to?.length > 0 ? (
                            <FormHelperText>
                              {errors?.assigned_to?.[0] || ''}
                            </FormHelperText>
                          ) : null}
                          {/* <FormHelperText>{errors?.assigned_to?.[0] || ''}</FormHelperText> */}
                        </FormControl>
                      </div>
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Status</div>
                        <CustomSelectField
                          name="status"
                          select
                          value={formData.status}
                          InputProps={{
                            style: {
                              height: '40px',
                              maxHeight: '40px',
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              PaperProps: {
                                style: {
                                  maxHeight: '200px',
                                },
                              },
                            },
                          }}
                          onChange={handleChange}
                          sx={{ width: '70%' }}
                          helperText={
                            errors?.status?.[0] ? errors?.status[0] : ''
                          }
                          error={!!errors?.status?.[0]}
                        >
                          {state?.status?.length &&
                            state?.status.map((option: any) => (
                              <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                              </MenuItem>
                            ))}
                        </CustomSelectField>
                      </div>
                    </div>
                    <div className="fieldContainer2">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Referral Source</div>
                        <CustomSelectField
                          name="source"
                          select
                          value={formData.source}
                          InputProps={{
                            style: {
                              height: '40px',
                              maxHeight: '40px',
                            },
                          }}
                          onChange={handleChange}
                          sx={{ width: '70%' }}
                          helperText={
                            errors?.source?.[0] ? errors?.source[0] : ''
                          }
                          error={!!errors?.source?.[0]}
                        >
                          {state?.source?.length &&
                            state?.source.map((option: any) => (
                              <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                              </MenuItem>
                            ))}
                        </CustomSelectField>
                      </div>
                      <div className="fieldSubContainer">
                        {/* <div className="fieldTitle">Tags</div>
                        <FormControl
                          error={!!errors?.tags?.[0]}
                          sx={{ width: '70%' }}
                        >
                          <Autocomplete
                            // ref={autocompleteRef}
                            value={selectedTags}
                            multiple
                            limitTags={5}
                            options={state.tags || []}
                            // options={state.contacts ? state.contacts.map((option: any) => option) : ['']}
                            getOptionLabel={(option: any) => option}
                            onChange={(e: any, value: any) =>
                              handleChange2('tags', value)
                            }
                            size="small"
                            filterSelectedOptions
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  deleteIcon={
                                    <FaTimes style={{ width: '9px' }} />
                                  }
                                  sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    height: '18px',
                                  }}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            popupIcon={
                              <IconButton
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                  width: '45px',
                                  height: '40px',
                                  borderRadius: '0px',
                                  backgroundColor: '#d3d3d34a',
                                }}
                              >
                                <FaPlus style={{ width: '15px' }} />
                              </IconButton>
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Add Tags"
                                InputProps={{
                                  ...params.InputProps,
                                  sx: {
                                    '& .MuiAutocomplete-endAdornment': {
                                      mt: '-9px',
                                      mr: '-8px',
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                          {state?.tags?.length > 0 ? (
                            <FormHelperText>
                              {errors?.tags?.[0] || ''}
                            </FormHelperText>
                          ) : null}
                          <FormHelperText>{errors?.tags?.[0] || ''}</FormHelperText>
                        </FormControl> */}
                      </div>
                    </div>            
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
            
            {/* insurance info */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
            <Accordion defaultExpanded style={{ width: '98%' }}>
              <AccordionSummary expandIcon={<FaArrowDown />}>
                <div
                  style={{
                    borderBottom: '1px solid lightgray',
                    width: '100%',
                  }}
                >
                  <Typography
                    style={{
                      marginBottom: '15px',
                      fontWeight: 'bold',
                      color: '#1A3353',
                    }}
                  >
                    Insurance Info
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <div className="fieldContainer">
                    <div className="fieldSubContainer" style={{display: "flex", alignItems: "center"}}>
                      <div className="fieldTitle" style={{width: "35%"}}>Do you have insurance?</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          name="insurance"
                          checked={formData.insurance === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                        />
                        <span style={{ marginRight: '10px' }}>Yes</span>
                        <Radio
                          name="insurance"
                          checked={formData.insurance === 'No'}
                          onChange={handleChange}
                          value="No"
                        />
                        <span>No</span>
                      </div>
                    </div>
                    
                    <div className="fieldSubContainer">
                    {formData.insurance === 'Yes' &&
                      ( <>
                          <div className="fieldTitle" style={{width: "10%", marginLeft: "80px" , display: "flex", alignItems: "center"}}>If Yes</div>
                          <TextField
                            name="insurance_name"
                            value={formData.insurance_name}
                            onChange={handleChange}
                            style={{ width: '80%' }}
                            size="small"
                            placeholder="Enter insurance name"
                          />
                        </>)}  
                    </div>               
                  </div>

                  <div className="fieldContainer2">
                    <div className="fieldSubContainer" style={{display: "flex", alignItems: "center"}}>
                      <div className="fieldTitle" style={{width: "35%"}}>Are you an Medicare?</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          name="medicare"
                          checked={formData.medicare === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                        />
                        <span style={{ marginRight: '10px' }}>Yes</span>
                        <Radio
                          name="medicare"
                          checked={formData.medicare === 'No'}
                          onChange={handleChange}
                          value="No"
                        />
                        <span>No</span>
                      </div>
                    </div>

                    <div className="fieldSubContainer">
                    </div>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
            </div>


            {/* medical info */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div
                    style={{
                      borderBottom: '1px solid lightgray',
                      width: '100%',
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: '15px',
                        fontWeight: 'bold',
                        color: '#1A3353',
                      }}
                    >
                      Medical Info
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    
                  <div className="fieldContainer" style={{display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                    <div className="fieldSubContainer" style={{ display: "flex", flexDirection: "column", width: "fit-content", gap: "10px"}}>
                      <div className="fieldTitle" style={{ width: "fit-content", marginRight: "0" }}>Reason for primary doctor</div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: "fit-content" }}>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                          <Checkbox
                            name="specific_complaint"
                            checked={formData.specific_complaint}
                            onChange={handleCheckboxChange}
                          />
                          <span style={{ marginLeft: '10px' }}>Specific Complaint</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                          <Checkbox
                            name="chromic_condition_management"
                            checked={formData.chromic_condition_management}
                            onChange={handleCheckboxChange}
                          />
                          <span style={{ marginLeft: '10px' }}>Chronic Condition Management</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                          <Checkbox
                            name="wellness_management"
                            checked={formData.wellness_management}
                            onChange={handleCheckboxChange}
                          />
                          <span style={{ marginLeft: '10px' }}>Wellness Management</span>
                        </div>
                      </div>
                    </div>


                    <div className="fieldSubContainer" style={{display: "flex", flexDirection: "column", marginTop: "-70px", alignItems: "center", width: "30%", gap: "10px"}}>
                      <div className="fieldTitle" style={{width: "fit-content", marginRight: "0"}}>Are you on prescriptions?</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          name="prescriptions"
                          checked={formData.prescriptions === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                        />
                        <span style={{ marginRight: '10px' }}>Yes</span>
                        <Radio
                          name="prescriptions"
                          checked={formData.prescriptions === 'No'}
                          onChange={handleChange}
                          value="No"
                        />
                        <span>No</span>
                      </div>
                    </div>
                  

                    <div className="fieldSubContainer" style={{display: "flex", flexDirection: "column", marginTop: "-70px", alignItems: "center", width: "30%", gap: "10px"}}>
                      <div className="fieldTitle" style={{width: "fit-content", marginRight: "0"}}>Are any of the prescriptions controlled substances?</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          name="controlled_prescriptions"
                          checked={formData.controlled_prescriptions === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                        />
                        <span style={{ marginRight: '10px' }}>Yes</span>
                        <Radio
                          name="controlled_prescriptions"
                          checked={formData.controlled_prescriptions === 'No'}
                          onChange={handleChange}
                          value="No"
                        />
                        <span>No</span>
                      </div>
                    </div>
                  </div>

                    <div className="fieldContainer2">
                    <div className="fieldSubContainer" style={{display: "flex", alignItems: "center"}}>
                      <div className="fieldTitle" style={{width: "fit-content"}}>Are you seeing an specialists now?</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          name="specialist"
                          checked={formData.specialist === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                        />
                        <span style={{ marginRight: '10px' }}>Yes</span>
                        <Radio
                          name="specialist"
                          checked={formData.specialist === 'No'}
                          onChange={handleChange}
                          value="No"
                        />
                        <span>No</span>
                      </div>
                    </div>
                    
                    <div className="fieldSubContainer">
                    {formData.specialist === 'Yes' &&
                      ( <>
                          <div className="fieldTitle" style={{width: "10%", marginLeft: "80px" , display: "flex", alignItems: "center"}}>If Yes</div>
                          <TextField
                            name="specialist_name"
                            value={formData.specialist_name}
                            onChange={handleChange}
                            style={{ width: '80%' }}
                            size="small"
                            placeholder="Enter Specialist name"
                          />
                        </>)}  
                    </div>               
                  </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* Description details  */}
            <div className="leadContainer">
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div className="typography">
                    <Typography
                      style={{ marginBottom: '15px', fontWeight: 'bold' }}
                    >
                      Description
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '100%', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="DescriptionDetail">
                      <div className="descriptionSubContainer">
                        <div className="descriptionTitle">Description</div>
                        <TextareaAutosize
                          name="description"
                          minRows={8}
                          value={formData.description}
                          onChange={handleChange}
                          style={{ width: '80%', padding: '5px' }}
                          placeholder="Add Description"
                          // error={!!errors?.description?.[0]}
                          // helperText={error && errors?.description?.[0] ? errors?.description[0] : ''}
                        />
                      </div>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
}
