
import { useRef, useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
// import { fetchData } from '../../components/FetchData';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import '../../styles/signin.css';
import '../../styles/Password.css';
import logo from '../../assets/images/auth/img_logo.png';
import illustration from '../../assets/images/auth/Illustration.png';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

declare global {
  interface Window {
    google: any;
    gapi: any;
  }
}

export function ResetPassword() {
  const navigate = useNavigate();

  const [inputDetail, setInputDetail] = useState({
   password: '',
   confirmPassword: ''
  });

  const [isPasswordNotEmpty, setIsPasswordNotEmpty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const passwordErrorRef = useRef<HTMLDivElement>(null);
  const confirmPasswordErrorRef = useRef<HTMLDivElement>(null);

  const {password, confirmPassword} = inputDetail;
  
  const handleInput = (event: any) => {
    const { id, value } = event.target;

    setInputDetail((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));

    if (id === 'password') {
        setIsPasswordNotEmpty(value !== '');
    }
    if (id === 'password' && passwordErrorRef.current) {
      passwordErrorRef.current.style.display = 'none';
    }
    else if (id === 'confirmPassword' && confirmPasswordErrorRef.current) {
        confirmPasswordErrorRef.current.style.display = 'none';
      }
    
  };

  const handleForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    if (
        !password.match(passwordPattern) &&
        passwordErrorRef.current
    ) {
        passwordErrorRef.current.style.display = 'block';
    }
  
    if (
        password !== confirmPassword &&
        confirmPasswordErrorRef.current
    ) {
        confirmPasswordErrorRef.current.style.display = 'block';
    }

    if (
        confirmPasswordErrorRef.current &&
        confirmPassword === ''
    ) {
        confirmPasswordErrorRef.current.style.display = 'block';
    }
    else {
      navigate('/login')
    }
  };

  const singinShowHandler = () => {
    navigate('/login');
  };

  const calculatePasswordStrength = (password: string): string => {
    if (password.length < 8) {
        return 'Poor';
    }

    const complexityRegex = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        numbers: /[0-9]/,
        specialChars: /[!@#$%^&*(),.?":{}|<>]/,
    };

    let count = 0;

    if (complexityRegex.lowerCase.test(password)) {
        count++;
    }
    if (complexityRegex.upperCase.test(password)) {
        count++;
    }
    if (complexityRegex.numbers.test(password)) {
        count++;
    }
    if (complexityRegex.specialChars.test(password)) {
        count++;
    }

    if (count === 1) {
        return 'Weak';
    }
     else if (count === 2) {
        return 'Moderate';
    }
     else if (count === 3) {
        return 'Strong';
    } 
    else if (count >= 4) {
        return 'Very Strong';
    }

    return 'Weak';
};


  

  return (
    <div className="loginContainer signupContainer">
      <nav className="loginNavbar">
        <img
          src={logo}
          alt="Patient Match"
          onClick={() => navigate('/login')}
        />
      </nav>
      <div className="loginSubSignupContainer resetDiv">
      <div className="signupLogo">
            <img src={illustration} alt="illustration" />
          </div>
        <div className="signupForm forgotContainer resetContainer">    
            <div className="signup forgotResetContainer resetPasswordContainer">
              <div>
              <h3 style={{textAlign: "center"}}>Reset Your Password</h3>
              <p className='forgotText'>Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.</p>
              </div>
              <form onChange={handleInput} onSubmit={handleForgotPassword}>   
              
              <div>
              <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  label="Password"
                  variant="outlined"
                  className="input"
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                  {isPasswordNotEmpty && (
                    <div className="password-strength-meter">
                      <div className={`strength ${calculatePasswordStrength(password)}`}>
                        Password Strength: {calculatePasswordStrength(password)}
                      </div>
                    </div>
                  )}
                  <div className="error" ref={passwordErrorRef}>
                    Password must contain at least 1 uppercase and lowercase letter, 1 special character, 1 number and 8 or more characters.
                  </div>
                </div>
    
                <div>
                  <TextField
                    type="password"
                    id="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    className="input"
                    value={confirmPassword}
                  />
                  <div className="error" ref={confirmPasswordErrorRef}>
                    Password and confirm passsword does not match.
                  </div>
                </div>

                <div className="bttnContainer bttnSigninGoogle">
                  <button type="submit" className="signinBtn">
                    Submit
                  </button>
                </div>              
              </form>
            </div>     
        </div>
      </div>
    </div>
  );
}
