import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useRef, useEffect, useState } from 'react';
// import { AuthUrl, OrgUrl, SERVER } from '../../components/ApiUrls'
// import { GoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
// import AuthComponent from '../../components/AuthSignIn'
// import GoogleLogin from '../../components/GoogleLogin'
// import GoogleLoginButto from '../../components/GoogleLogin2'
//  import Google from '../../components/GoogleLogin1'
import imgLogo from '../../assets/images/auth/img_logo.png';
import imgBG from '../../assets/images/auth/img_BG.jpg';
import imgLogin from '../../assets/images/auth/img_login.png';
import '../../styles/auth.css';
import { googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
// import { fetchData } from '../../components/FetchData';
import { useNavigate } from 'react-router-dom';
import imgGoogle from '../../assets/images/auth/google.svg';
import { GoogleButton } from '../../styles/CssStyled';
import { fetchData } from '../../components/FetchData';
import { AuthUrl, OrgUrl } from '../../services/ApiUrls';
import TextField from '@mui/material/TextField';
// import { GoogleButton } from '../../../../react-crm-2.0/src/styles/CssStyled';
import '../../styles/signin.css';
import logo from '../../assets/images/auth/img_logo.png';
import UserDetails from '../users/UserDetails';
import illustration from '../../assets/images/auth/Illustration.png';
import { LoginUrl } from '../../services/ApiUrls';
import { Register } from '../../services/ApiUrls';
import { FaLeaf } from 'react-icons/fa';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    google: any;
    gapi: any;
  }
}

let timerId: any;

export default function Signup() {
  const navigate = useNavigate();
  const [token, setToken] = useState(false);

  
  // useEffect(() => {
  //   if (localStorage.getItem('Token')) {
  //     // navigate('/organization')
  //     navigate('/app');
  //   }
  // }, [token]);
  // useEffect(() => {
  //     const token = localStorage.getItem('Token');
  //     if (token) {
  //       navigate('/organization');
  //     }
  //   }, [navigate]);
  // const headers = {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     //   Authorization: `jwt ${localStorage.getItem('Token')}`,
  //     //   org: localStorage.getItem('org')
  // }

  const [hasOrg, setHasOrg] = useState(false);


  
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const apiToken = { token: tokenResponse.access_token };
      // const formData = new FormData()
      // formData.append('token', tokenResponse.access_token)
      const head = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      fetchData(`${AuthUrl}/`, 'POST', JSON.stringify(apiToken), head)
        .then((res: any) => {
          // console.log(res.access_token, 'access_token')
          localStorage.setItem('Token', `Bearer ${res.access_token}`);
          setToken(true);
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    },
  });

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
  };

  const getOrganization = () => {
    fetchData(`${OrgUrl}/`, 'GET', null as any, headers)
      .then((res: any) => {
        // console.log(res, 'org')
        if (res?.profile_org_list[0]?.org?.id && localStorage.getItem('Token')) {         
          localStorage.setItem('org', res?.profile_org_list[0]?.org?.id); 
          setHasOrg(true);
          localStorage.setItem('orgName', res?.profile_org_list[0]?.org?.name); 
          // localStorage.setItem('orgName', res?.profile_org_list[0]?.org?.name); 
          // console.log("org",res?.profile_org_list);   
          navigate('/app/leads',  {
            state: { orgName: res?.profile_org_list[0]?.org?.name },
          });     
        }else if(localStorage.getItem('Token') && !hasOrg) {
          navigate('/app');
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        setHasOrg(false);
      });
  };
  
  useEffect(() => {
    getOrganization();
  }, [token]);

  const [signupDetails, setSignupDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    number: '',
    password: '',
    confirmPassword: '',
  });

  const {firstname, lastname, email, number, password, confirmPassword} = signupDetails;

  const [singupSuccess, setSignupSuccess] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const [isPasswordNotEmpty, setIsPasswordNotEmpty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const firstnameErrorRef = useRef<HTMLDivElement>(null);
  const lastnameErrorRef = useRef<HTMLDivElement>(null);
  const emailSignupErrorRef = useRef<HTMLDivElement>(null);
  const numberErrorRef = useRef<HTMLDivElement>(null);
  const passwordSignupErrorRef = useRef<HTMLDivElement>(null);
  const confirmPasswordErrorRef = useRef<HTMLDivElement>(null);

  const passswordStrengthRef = useRef<HTMLDivElement>(null);

  

  const handleInput = (event: any) => {
    const { id, value } = event.target;

    setSignupDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));

    if (id === 'password') {
      setIsPasswordNotEmpty(value !== '');
    }

    if (id === 'firstname' && firstnameErrorRef.current) {
      firstnameErrorRef.current.style.display = 'none';
    }
    else if (id === 'lastname' && lastnameErrorRef.current) {
      lastnameErrorRef.current.style.display = 'none';
    }
    else if (id === 'email' && emailSignupErrorRef.current) {
      emailSignupErrorRef.current.style.display = 'none';
    } 
    else if (id === 'number' && numberErrorRef.current) {
      numberErrorRef.current.style.display = 'none';
    } 
    else if (id === 'password' && passwordSignupErrorRef.current) {
      passwordSignupErrorRef.current.style.display = 'none';
    }
    else if (id === 'confirmPassword' && confirmPasswordErrorRef.current) {
      confirmPasswordErrorRef.current.style.display = 'none';
    }
  };

  const handleSignup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/;
    const mobilePattern = /^(?:\+1|1)?[-.\s]?\(?(?=\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$)\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    let hasValidationErrors = false;

    if (firstname === '' && firstnameErrorRef.current) {
      firstnameErrorRef.current.style.display = 'block';
      hasValidationErrors = true;
    }

    if (lastname === '' && lastnameErrorRef.current) {
      lastnameErrorRef.current.style.display = 'block';
      hasValidationErrors = true;
    }

    if (
      !email.match(emailPattern) &&
      emailSignupErrorRef.current
    ) {
      emailSignupErrorRef.current.style.display = 'block';
      emailSignupErrorRef.current.innerHTML = 'Please enter a valid email address.';
      hasValidationErrors = true;
    }

    if(!number.match(mobilePattern) && numberErrorRef.current) {
      numberErrorRef.current.style.display = 'block';
      numberErrorRef.current.innerHTML = 'Please enter valid mobile number';
      hasValidationErrors = true;
    }

    if (
      !password.match(passwordPattern) &&
      passwordSignupErrorRef.current
    ) {
      passwordSignupErrorRef.current.style.display = 'block';
      hasValidationErrors = true;
    }

    if (
      password !== confirmPassword &&
      confirmPasswordErrorRef.current
    ) {
      confirmPasswordErrorRef.current.style.display = 'block';
      hasValidationErrors = true;
    }

    if (
      confirmPasswordErrorRef.current &&
      confirmPassword === ''
    ) {
      confirmPasswordErrorRef.current.style.display = 'block';
      hasValidationErrors = true;
    }
    
    if (!hasValidationErrors) {
      signup();
    }
  };

  const registerHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const signup = () => {
    fetchData(`${Register}/`, 'POST', JSON.stringify(signupDetails), registerHeader)
      .then((res: any) => {
        console.log('Response from server:', res);
        if (res.email === email) {
          console.log('User Register Details', res);
          setSignupDetails({
            firstname: '',
            lastname: '',
            email: '',
            number: '',
            password: '',
            confirmPassword: '',       
          })
          setSignupSuccess(true);
        }
        else { 
          if (res.number && res.number[0] === 'Mobile number already exists' && numberErrorRef.current) {
            numberErrorRef.current.style.display = 'block';
            numberErrorRef.current.innerText = 'Mobile number already registered';
          }        
          if(res.email[0] = 'Email id already registered' && emailSignupErrorRef.current) {
            emailSignupErrorRef.current.style.display = 'block';
            emailSignupErrorRef.current.innerText = 'Email id already registered';
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if(singupSuccess) {
      setShowRegister(true);
      if(passswordStrengthRef.current) {
        passswordStrengthRef.current.style.display = "none";
      }
      timerId = setTimeout(() => {
        navigate('/login');
      }, 2000);     
    }

    return () =>{
      setShowRegister(false);
      clearTimeout(timerId);
    }
  }, [singupSuccess])

  

  const singinShowHandler = () => {
    navigate('/login');
  };

  const calculatePasswordStrength = (password: string): string => {
    if (password.length < 8) {
        return 'Poor';
    }

    const complexityRegex = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        numbers: /[0-9]/,
        specialChars: /[!@#$%^&*(),.?":{}|<>]/,
    };

    let count = 0;

    if (complexityRegex.lowerCase.test(password)) {
        count++;
    }
    if (complexityRegex.upperCase.test(password)) {
        count++;
    }
    if (complexityRegex.numbers.test(password)) {
        count++;
    }
    if (complexityRegex.specialChars.test(password)) {
        count++;
    }

    if (count === 1) {
        return 'Poor';
    }
     else if (count === 2) {
        return 'Weak';
    }
     else if (count === 3) {
        return 'Moderate';
    } 
    else if (count >= 4) {
        return 'Very Strong';
    }

    return 'Weak';
};

// const validateUSPhoneNumber = (phoneNumber: string) => {
//   const usPhoneRegex = /^(?:\+1|1)?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
//   return usPhoneRegex.test(phoneNumber);
// };
  
  
  return (
    <div className="loginContainer signupContainer">
      <nav className="loginNavbar">
        <img
          src={logo}
          alt="Patient Match"
          onClick={() => navigate('/login')}
        />
      </nav>
      <div className="loginSubSignupContainer">
      <div className="signupLogo">
          <img src={illustration} alt="illustration" />
        </div>
        <div className="signupForm">    
            <div className="signup">
              {showRegister && <div className='successfullContainer'>Successfully Registered !!</div>}
              <h3>Sign Up</h3>
              <form onChange={handleInput} onSubmit={handleSignup}>
                <div className='nameContainer'>
                  <div>
                    <TextField
                      type="text"
                      id="firstname"
                      label="First Name"
                      variant="outlined"
                      className="input inputName"
                      value={firstname}
                    />
                    <div className="error" ref={firstnameErrorRef}>
                      Please enter firstname.
                    </div>
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="lastname"
                      label="Last Name"
                      variant="outlined"
                      className="input inputName"
                      value={lastname}
                    />
                    <div className="error" ref={lastnameErrorRef}>
                      Please enter lastname.
                    </div>
                  </div>
                </div>

              
                <div>
                  <TextField
                    type="email"
                    id="email"
                    label="Email"
                    variant="outlined"
                    className="input"
                    value={email}
                  />
                  <div className="error" ref={emailSignupErrorRef}></div>
                </div>

                <div>
                <TextField
                  id="number"
                  label="Mobile Number"
                  type="text"
                  variant="outlined"
                  className="input"
                  value={number}
                  // helperText={
                  //   number.length > 0 && !validateUSPhoneNumber(number) ? 'Invalid mobile number' : ''
                  // }
                />
                  <div className="error" ref={numberErrorRef}></div>
                </div>
               
                <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  label="Password"
                  variant="outlined"
                  className="input"
                  value={password}            
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                  {isPasswordNotEmpty && (
                    <div className="password-strength-meter" ref={passswordStrengthRef}>
                      <div className={`strength ${calculatePasswordStrength(password)}`}>
                        Password Strength: {calculatePasswordStrength(password)}
                      </div>
                    </div>
                  )}
                  <div className="error" ref={passwordSignupErrorRef}>
                    Password must contain at least 1 uppercase, 1 lowercase letter, 1 special character, 1 number and 8 or more characters.
                  </div>
                </div>

                
                <div>
                  <TextField
                    type="password"
                    id="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    className="input"
                    value={confirmPassword}
                  />
                  <div className="error" ref={confirmPasswordErrorRef}>
                    Password and Confirm Password does not match.
                  </div>
                </div>

                <div className="bttnContainer bttnSigninGoogle">
                  <button type="submit" className="signinBtn">
                    Sign Up
                  </button>
                  {/* <div className="orLine">
                    <div></div>
                    <p>OR</p>
                    <div></div>
                  </div>
                  <GoogleButton
                    variant="outlined"
                    onClick={() => login()}
                    className="googleBttn"
                  >
                    <img
                      src={imgGoogle}
                      alt="google"
                      style={{ width: '20px', marginRight: '8px' }}
                    />
                    Sign in with Google
                  </GoogleButton> */}
                </div>

                
                <div className="noAccount">
                  Already have an account?{' '}
                  <span onClick={singinShowHandler}>Sign in</span>
                </div>
              </form>
            </div>     
        </div>
      </div>
    </div>
  );
}
