import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { FaEllipsisH, FaTrash } from "react-icons/fa";
import { Column, Id, Task } from "./types";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import TaskCard from "./TaskCard";
import "../../styles/style.css";
import { List, ListItem, Popover } from '@mui/material';
import { Box, Dialog } from "@mui/material";
import { fetchData } from "../../components/FetchData";
import { Boards, Cards, Columns } from "../../services/ApiUrls";

// interface Props {
//   column: Column;
//   deleteColumn: (id: Id) => void;
//   updateColumn: (id: Id, title: string) => void;
//   createTask: (columnId: Id) => void;
//   updateTask: (id: Id, updatedContent: { name?: string; email?: string; mobileNumber?: string; date?: string }) => void;
//   deleteTask: (id: Id) => void;
//   tasks: Task[];
// }

function ColumnContainer(props: any) {

  const { columnData, getColumns } = props;

  const [editMode, setEditMode] = useState(false);

  const [currentColumn, setCurrentColumn] = useState<any>(null);
  const [columnName, setColumnName] = useState<any>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [cards, setCards] = useState<any>([]);

  const handleClick = (event: any, columnData: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentColumn(columnData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tasksIds = useMemo(() => {
    return cards.map((card: any) => card.id);
  }, [cards]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: columnData.id,
    data: {
      type: "Column",
      columnData,
    },
    disabled: editMode,
  });

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        className="dragged-container"
      ></div>
    );
  }

  const openEditModal = () => {
    setAnchorEl(null);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setColumnName("");
    setEditModalIsOpen(false);
  };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
    org: localStorage.getItem('org'),
  };

  const deleteColumn = (id: any) => {
    fetchData(`${Boards}/${Columns}/${id}/`, 'DELETE', null as any, headers)
      .then((res: any) => {
        if (res) {
          console.log('Board Deleted', res);
          getColumns();
          setEditModalIsOpen(false);
          setAnchorEl(null);
        }
      })
      .catch((error) => {
        getColumns();
        setEditModalIsOpen(false);
        setAnchorEl(null);
        console.error('Error:', error);
      });
  };

  const headerss = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
  };

  const newData = {
    name: columnName,
  }

  const editBoard = (id: any) => {
    if (columnName === "") {
      inputRef.current?.focus();
    } else {
      fetchData(`${Boards}/${Columns}/${id}/`, 'PATCH', JSON.stringify(newData), headerss)
        .then((res: any) => {
          console.log('Board-Name updated: ', columnName, res)
          if (res) {
            setEditModalIsOpen(false);
            setColumnName("");
            setAnchorEl(null);
            getColumns();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getCards = () => {
    fetchData(`${Boards}/${Cards}/?board_id=${columnData.board}&board_column=${columnData.id}`, 'GET', null as any, headers)
      .then((res: any) => {
          console.log('Cards', res)
          if (res?.results) {
          setCards(res?.results);
          }
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }

  // useEffect(() => {
  //   getCards();
  // }, []);

  return (
    <div ref={setNodeRef} className="column-container">
      {/* Column title */}
      <div className="column-title">
        <div className="title-content">
          <p>{columnData.name}</p>
          {/* {editMode && (
            <input
              className="title-input"
              value={column.title}
              onChange={(e) => updateColumn(column.id, e.target.value)}
              autoFocus
              onBlur={() => {
                setEditMode(false);
              }}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                setEditMode(false);
              }}
            />
          )} */}
          {/* <div className="task-count">{tasks.length}</div> */}
        </div>
        <FaEllipsisH className="ellipsisButton" fill='#1a3353' onClick={(event) => handleClick(event, columnData)}  />
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={open && currentColumn ? `simple-popover-${currentColumn?.id}` : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <List disablePadding className="taskCardList" style={{width: "150px"}}>        
              <ListItem disablePadding className="taskCardListItem" onClick={openEditModal}  style={{ cursor: "pointer"}}>
                <button className="edit-button" style={{all: "unset", cursor: "pointer"}}>
                  Edit 
                </button>         
              </ListItem >

              <ListItem disablePadding className="taskCardListItem"  onClick={() => deleteColumn(currentColumn?.id)} style={{ cursor: "pointer"}}>
                <button
                  className="delete-button"
                >
                  Delete
                </button>    
                </ListItem>
            </List>
          </Popover>

          {/* Modal for edit board name */}
          <Dialog
            open={editModalIsOpen}
            onClose={closeEditModal}
          >
            <Box sx={{ width: '400px', display: "flex", flexDirection: "column", padding: "10px 30px 20px 30px", gap: "20px", borderRadius: "4px" }}>
              <h2 style={{textAlign: "center", color: "#1a3353", fontSize: "20px"}}>Edit Column</h2>
              
              <label className="boardNameModalInput">
                Column Name:
                <input
                  type="text"
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                  placeholder="Type here ..."
                  ref={inputRef}
                />  
              </label>

              {/* Edit and Cancel buttons */}
              <div  className="editTaskCardBttn">
                  <button onClick={() => editBoard(currentColumn?.id)}>Edit</button>
                  <button onClick={closeEditModal}>Cancel</button>
              </div>
            </Box>
          </Dialog>
        {/* <button
          onClick={() => {
            deleteColumn(column.id);
          }}
          className="delete-button"
        >
          <FaTrash />
        </button> */}
      </div>

      <button
        className="add-task-button"
        // onClick={() => {
        //   createTask(column.id);
        // }}
        title="Create-Cards"
      >
        <FaPlus />
      </button>

      {/* Column cards container */}
      <div className="task-container">
        {/* <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              deleteTask={deleteTask}
              updateTask={updateTask}
            />
          ))}
        </SortableContext> */}
        <SortableContext items={tasksIds}>
          {cards.map((card: any) => (
            <TaskCard key={card.id} cardData={card} getCards={getCards}/>
          ))}
        </SortableContext>
      </div>
    </div>
  );
}

export default ColumnContainer;
